@use "colors";

.input {
  flex: 1 1 100%;
  font-family: inherit;

  input,
  label {
    font-family: inherit;
    font-size: inherit;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiOutlinedInput-notchedOutline legend {
    font-family: inherit;
    font-size: 13px;
  }
}

.menu-item {
  font-size: 1.4rem !important;
}
