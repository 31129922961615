@use "colors";
@use "typography";

.form-field {
  /* stylelint-disable selector-class-pattern */
  :global .MuiFormHelperText-root {
    @include typography.body-extra-small;

    margin-inline-start: 0;
  }
  /* stylelint-enable selector-class-pattern */

  /* stylelint-disable selector-class-pattern */
  :global .MuiInputLabel-root,
  :global .MuiInput-input {
    @include typography.body;

    color: colors.$black;
    margin: 0;
    padding: 0;
  }
  /* stylelint-enable selector-class-pattern */

  /* stylelint-disable selector-class-pattern */
  :global .MuiInputLabel-root {
    color: colors.$black;
  }
  /* stylelint-enable selector-class-pattern */

  /* stylelint-disable selector-class-pattern */
  :global .MuiInputLabel-root.Mui-error {
    color: colors.$error-red;
  }
  /* stylelint-enable selector-class-pattern */

  /* stylelint-disable selector-class-pattern */
  :global .MuiInputBase-root {
    border-radius: 0;
    font-family: inherit;
    font-size: inherit;
  }
  /* stylelint-enable selector-class-pattern */

  /* stylelint-disable selector-class-pattern */
  :global .Mui-focused::before,
  :global .MuiInput-root::after,
  :global .Mui-focused::after {
    border-color: colors.$medium-gray;
    color: colors.$medium-gray;
  }
  /* stylelint-enable selector-class-pattern */

  /* stylelint-disable selector-class-pattern */
  :global .MuiInput-root:hover::before {
    border-block-end: 2px solid colors.$medium-gray;
  }
  /* stylelint-enable selector-class-pattern */

  [data-shrink="true"] {
    color: colors.$medium-gray !important;
  }
}
