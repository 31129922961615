@use "breakpoints";
@use "buttons";
@use "colors";
@use "layout";
@use "typography";
@import "custom";

.page-hero {
  block-size: calc(100dvh - var(--global-header-height));
  display: grid;
  margin-block-end: 10rem;

  &[data-compact="true"] {
    block-size: auto;
  }
}

.hero-text {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  position: relative;
  z-index: 10;
}

.background-media.background-media-container {
  block-size: 100%;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  padding: 0;
}

.home-page-hero {
  display: flex;
  inline-size: 100%;
  margin-block-end: 10rem;
  overflow: hidden;
  position: relative;
  user-select: none;

  &.minimum-height {
    min-block-size: 100vh;

    @media screen and (width <= 650px) and (height >= 700px) {
      min-block-size: 110vh;
    }

    @media screen and (width <= 650px) and (height <= 700px) {
      min-block-size: 140vh;
    }
  }

  .hero-contents {
    block-size: 50%;
    inline-size: 100%;
    inset-block-start: 0;
    position: absolute;
    z-index: 10;
  }

  .hero-inner {
    @include layout.module-content;

    flex: 1;
    transition: padding 0.2s ease-in-out;

    &-text {
      position: relative;

      &-right {
        h1 {
          text-align: end;
        }

        p {
          margin-inline-start: auto !important;
          text-align: end;
        }
      }
    }
  }

  .sub-nav {
    margin: 50px 0;
    z-index: 1;
  }

  .cta-wrapper {
    inline-size: 100%;

    .cta {
      @include buttons.button(
        buttons.$theme-primary-light,
        buttons.$width-primary
      );
    }

    .white-button,
    .transparent-button {
      margin-block-end: 20px;
    }

    .white-button {
      background: colors.$white;
      color: colors.$black;
    }

    .borderless-button {
      border: none;
      inline-size: auto !important;

      span {
        border-block-end: 1px solid colors.$medium-gray;
        color: colors.$white;
        padding-block-end: 5px;
      }

      &:hover {
        span {
          color: colors.$white;
        }

        background-color: transparent !important;
      }
    }
  }

  .user-interaction-style {
    margin-block: 30px 40px;

    &-right {
      position: relative;
      text-align: end;

      button {
        margin-inline: auto 0;
      }
    }
  }

  @media (width >= breakpoints.$sm) {
    .cta-wrapper {
      inline-size: auto;

      .white-button,
      .transparent-button {
        margin-block-end: 0;
      }
    }
  }
}

.background-media-container {
  block-size: 0;
  inline-size: 100%;
  padding-block-start: 177%;
  position: relative;
  z-index: 8;

  > div {
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    position: absolute;
  }

  @include media-breakpoint-up(lg) {
    padding-block-start: 56.25%;
  }
}

.background-article-container {
  padding-block-start: 100%;

  @include media-breakpoint-up(lg) {
    padding-block-start: 56.25%;
  }
}

.background-image {
  block-size: 100%;
  inline-size: 100%;
  position: relative;

  img,
  video {
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover;
    object-position: center top;
    position: relative;
    z-index: -1;
  }
}
