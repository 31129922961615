.formatted-text {
  white-space: pre-line;

  code {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    word-wrap: inherit;

    u {
      text-decoration: line-through;
    }
  }
}

.formatted-text-multi-line {
  white-space: pre-wrap;
}
