.input {
  border-block-end: none;
  caret-color: transparent;
  flex: 1 1 100%;
  font-family: inherit;
  margin-block: 5px 28px;
  padding-block-start: 2.4rem;

  input,
  label {
    cursor: pointer;
    font-family: inherit;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiInputBase-root {
    font-family: inherit;
    margin-block-end: 5px;
    min-block-size: 5.4rem;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiOutlinedInput-notchedOutline legend {
    font-family: inherit;
    font-size: 13px;
  }
}

.input-hidden {
  border: none;
  position: absolute;
  visibility: hidden;
}

.calendar-icon {
  margin-block-start: 20px;
}

.calendar-wrapper {
  inline-size: 100%;

  button {
    inset-block-start: 0 !important;
  }
}

.datepicker-label {
  cursor: pointer;
}

.datepicker-label-icon {
  color: #666; /* Color of the icon */
  content: "\1F4C5"; /* Unicode character for a calendar icon */
  inset-block-start: 50%;
  inset-inline-end: 10px; /* Adjust the positioning as needed */
  pointer-events: none; /* Ensure the icon doesn't interfere with input interaction */
  position: absolute;
  transform: translateY(-50%);
}
