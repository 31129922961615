// @use "stacking";

.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 3em 0;

  &[aria-modal="true"] {
    inset: 0;
    margin: 0;
    position: fixed;
    z-index: 100;
  }

  &[data-background-shade="true"] {
    background-color: rgba(0, 0, 0, 50%);
  }

  &[data-overlay="true"] {
    inset: 0;
    position: absolute;
    z-index: 10;
  }
}

.spinner {
  block-size: 3em;
  inline-size: 3em;
  opacity: 0.7;

  :global .visually-hidden {
    block-size: 1px;
    border: 0;
    clip: rect(0, 0, 0, 0);
    inline-size: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
  }
}
