@use "colors";

.input {
  border-block-end: none;
  flex: 1 1 100%;
  font-family: inherit;
  margin-block: 5px 28px !important;

  input,
  label {
    font-family: inherit;
    font-size: inherit;
    padding: 1.5rem;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiInputBase-root {
    font-family: inherit;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiOutlinedInput-notchedOutline legend {
    font-family: inherit;
    font-size: 13px;
  }
}
