@use "colors";
@use "typography";

.main {
  display: flex;
  flex-direction: column;
  min-block-size: 100dvh;

  > section {
    flex: 1 1 auto;
    inline-size: 100%;
    position: relative;
  }

  &[data-layout-block="start"] {
    justify-content: start;
  }

  &[data-layout-block="center"] {
    justify-content: center;
  }

  &[data-layout-block="end"] {
    justify-content: end;
  }

  &[data-module-spacing="none"] {
    > [data-is="PageHero"] {
      margin-block-end: 1.2rem !important;
      padding-block-end: 1.2rem !important;
    }

    > section {
      margin-block: 0 !important;
      padding-block: 0 !important;
    }
  }

  &[data-module-spacing="compact"] {
    > header + section,
    > section + section {
      margin-block-start: 2.4rem !important;
      padding-block-start: 2.4rem !important;
    }
  }

  &[data-module-spacing="regular"] {
    > header + section,
    > section + section {
      margin-block-start: 5.6rem !important;
      padding-block-start: 5.6rem !important;
    }
  }

  &[data-module-spacing="loose"] {
    > header + section,
    > section + section {
      margin-block-start: 8.4rem !important;
      padding-block-start: 8.4rem !important;
    }
  }

  &[data-page="kidsclub"] {
    [data-is="TextMarkdown"] {
      margin-block-end: 10rem;

      h3 {
        letter-spacing: 0.2em;
        margin: 30px auto;
        text-transform: uppercase;
      }

      p {
        color: colors.$off-black;
        letter-spacing: 1.25px;
        line-height: 25px;
        padding: 0 5px;
      }
    }
  }
}

.main[data-theme="dark"] {
  background-color: colors.$black;
  color: colors.$white;
}

.main[data-theme="light"] {
  background-color: colors.$white;
  color: colors.$off-black;
}

header + .main {
  margin-block-start: var(--global-header-height);
  min-block-size: calc(100dvh - var(--global-header-height));
}
