@import "custom";
@import "variables";
@import "mixins";

.z-index {
  z-index: 999 !important;

  .calendar-wrapper {
    border: 1px solid black;
    border-radius: 0;
    font-size: 1.6rem;

    button {
      inset-block-start: 8px;
    }

    div:first-child {
      background-color: white;
    }

    .week-day {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .selected-day {
      background-color: black !important;
      color: white;
    }
  }
}

.input {
  color: black;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-block-end: -20px;
  padding-inline-start: 0;
  text-align: start;

  &[disabled] {
    opacity: 0.6;
  }
}

.calendar-icon {
  inset-block-start: 0;
  inset-inline-end: 10px;
  position: absolute;
}

.datepicker-width {
  inline-size: 100%;
}
