@use "breakpoints";
@use "colors";
@use "layout";
@use "typography";

.mailing-list-form {
  background-color: colors.$white;
  padding-block: 9.6rem;
}

.content {
  @include layout.module-content-grid;

  padding-block: 1rem;

  @media (min-width: breakpoints.$lg) {
    padding-block: 2rem;
  }
}

.headline {
  @include typography.large-headline;

  color: colors.$black;
  grid-column: 1 / span 12;
  margin-block-end: 4.8rem;
}

.form {
  display: contents;
}

.first-name-field {
  grid-column: 1 / span 12;
}

.last-name-field {
  grid-column: 1 / span 12;
}

.email-address-field {
  grid-column: 1 / span 12;
  position: relative;
}

.submit-button {
  background: none;
  border: none;
  inset-inline-end: 0;
  position: absolute;
  z-index: 10;

  &:disabled {
    opacity: 0.5;
  }

  svg {
    margin-inline-end: -5px;
    transition: margin-right 0.2s ease-in-out;

    path {
      fill: colors.$black;
    }
  }

  &:hover {
    svg {
      margin-inline-end: 5px;
    }
  }
}

.status-text {
  @include typography.body-small;

  color: colors.$black;
  grid-column: 1 / span 12;

  &[data-has-error="true"] {
    color: colors.$error-red;
  }
}

@media (width >= breakpoints.$md) {
  .first-name-field {
    grid-column: 1 / span 6;
  }

  .last-name-field {
    grid-column: 7 / span 6;
  }
}

@media (width >= breakpoints.$lg) {
  .headline {
    grid-column: 2 / span 5;
    grid-row: 1 / span 3;
    margin-block: 2.4rem 0;
  }

  .first-name-field {
    grid-column: 7 / span 4;
    grid-row: 1 / span 1;
  }

  .last-name-field {
    grid-column: 11 / span 4;
    grid-row: 1 / span 1;
  }

  .email-address-field {
    grid-column: 7 / span 8;
    grid-row: 2 / span 1;
  }

  .status-text {
    grid-column: 7 / span 8;
    grid-row: 3 / span 1;
  }
}
